<template>
  <div class="activate-site">
    <!-- Header Content -->
    <div class="header-site" style="font-size: 30px">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back</b-button
        >
      </router-link>
      {{ "Site Activation" }}
      <h1 v-if="loading">Activating Site...</h1>
    </div>
    <div class="create-center">
      <div class="site-owner">
        <div>
          <span class="bold-text">Site Owner:</span> You must assign a site
          owner before activating a site!
        </div>
        <b-dropdown :text="localSiteOwner || 'Set Site Owner'" block>
          <b-dropdown-item-button
            v-for="(person, index) in customerSuccessManagers"
            :key="index"
            @click.prevent="siteOwner = person"
          >
            {{ person }}
          </b-dropdown-item-button>
        </b-dropdown>
      </div>

      <div style="text-align: left">
        Input an email address to act as the email and username for the customer
        that is being onboarded.
      </div>
      <b-form id="form-style" @submit="onSubmit" @reset="onReset">
        <!-- User Email  -->
        <b-form-group id="input-group-2">
          <b-form-input
            autocomplete="off"
            id="input-2"
            v-model="userEmail"
            type="email"
            placeholder="User Email"
            required
            :disabled="loading"
          >
          </b-form-input>
          <!-- radio button  -->
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="blankClientRadio"
              v-model="newClient"
              :value="true"
              checked
            />
            <label class="form-check-label" for="blankClientRadio">
              Attach Blank Client
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="existingClientRadio"
              v-model="newClient"
              :value="false"
            />
            <label class="form-check-label" for="existingClientRadio">
              Use Connected Client
            </label>
          </div>
          <b-form-input
            @keydown.space.prevent
            v-if="newClient"
            autocomplete="off"
            id="input-2"
            v-model="clientName"
            type="text"
            placeholder="ID/Name For Blank Client"
            required
            :disabled="loading"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group style="padding: 10px">
          <b-button
            type="submit"
            variant="outline-primary"
            style="margin-right: 5px"
            :disabled="loading || !localSiteOwner"
            >Submit</b-button
          >
          <b-button
            type="reset"
            variant="outline-danger"
            style="margin-left: 5px"
            :disabled="loading"
            >Reset</b-button
          >
        </b-form-group>
      </b-form>
    </div>
    <div class="current-site">Current Site</div>
    <iframe :src="getIframeURL()" class="iframe"></iframe>
  </div>
</template>

<script>
import { customerSuccessManagers } from "@/utils/constants";
import updateSiteInfoMixin from "@/mixins/updateSiteInfo.js";

export default {
  mixins: [updateSiteInfoMixin],
  data() {
    return {
      userEmail: "",
      newClient: true,
      clientName: "",
      loading: false,
      customerSuccessManagers,
      localSiteOwner: "",
    };
  },
  computed: {
    siteID() {
      return this.$route.params.siteID;
    },
    siteName() {
      const siteList = this.$store.getters.getSites;
      return siteList[
        siteList.findIndex((siteInfo) => siteInfo.siteID === this.siteID)
      ].styleName;
    },
    siteCsDetails() {
      const siteList = this.$store.getters.getSites;
      return siteList[
        siteList.findIndex((siteInfo) => siteInfo.siteID === this.siteID)
      ].csDetails;
    },
    siteOwner: {
      get() {
        const siteList = this.$store.getters.getSites;
        if (!siteList) return "";
        return siteList[
          siteList.findIndex((siteInfo) => siteInfo.siteID === this.siteID)
        ].csDetails.siteOwner;
      },
      set(siteOwner) {
        const newDetails = { ...this.siteCsDetails, siteOwner };
        this.updateSiteInfo(this.siteID, "Owner", { csDetails: newDetails });
        this.localSiteOwner = siteOwner;
      },
    },
  },
  mounted() {
    this.localSiteOwner = this.siteOwner;
  },
  methods: {
    getIframeURL() {
      return `${process.env.VUE_APP_APP_ROOT}/home/${this.$route.params.siteID}?touchscreen=true`;
    },
    onSubmit(event) {
      event.preventDefault();
      // if attach to blank client then create new collection using the name for the blank client
      if (this.newClient) {
        this.loading = true;
        this.$api
          .post(`/clientCollection/${this.clientName}`)
          .then((res) => {
            this.updateSiteIDAndCreateUser(res.data.id);
          })
          .catch((e) => {
            alert("Error activating site: " + e.response.data.error);
          });
      } else {
        this.updateSiteIDAndCreateUser(null);
      }
    },
    // patch siteID to update isActivated and the clientID
    // post /grantSiteAccess
    updateSiteIDAndCreateUser(clientID) {
      this.loading = true;
      // make sure correct clientID is attached
      let updateBody = { isActivated: true, activationDate: Date.now() };

      // if a new clientID was created attach it to the siteID doc
      if (clientID) {
        updateBody.clientID = clientID;
      }

      // create the user - 409 means they already exist (409 status intercepted in api.service.js)
      this.$api
        .post(`/users`, {
          email: this.userEmail,
          role: "site-admin",
        })
        .then(() => {
          this.$api.patch(`/siteID/${this.siteID}`, updateBody).then(() => {
            this.$api
              .post("/grantSiteAccess", {
                email: this.userEmail,
                siteID: this.siteID,
                includeKickoffCallLink: true,
              })
              .then(() => {
                this.loading = false;
                alert(
                  `Site Activated and access granted to ${this.userEmail} for site ${this.siteID} (${this.siteName})`
                );
                this.$router.push("/");
              });
          });
        })
        .catch((e) => {
          alert(
            "Error occurred. Please contact support. " + e.response.data.error
          );
        });
    },
    onReset(event) {
      event.preventDefault();
      this.userEmail = "";
    },
  },
};
</script>

<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.header-site {
  padding: 30px;
  background-color: #133353;
  color: white;
}
.current-site {
  font-size: 2vw;
  margin-top: 2vh;
  font-weight: bold;
}
.iframe {
  transform: scale(0.5);
  height: 150vh;
  width: 160vw;
  margin-left: -30vw;
  margin-top: -35vh;
  border: 25px solid black;
  border-radius: 15px;
}

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d6e6f0 !important;
}
.btn-outline-primary {
  color: #133353 !important;
  border-color: #133353 !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: #133353 !important;
}
.btn-outline-danger {
  color: #f08080 !important;
  border-color: #f08080 !important;
}

.btn-outline-danger:hover {
  color: #f08080 !important;
  border-color: #f08080 !important;
}
.upload-file {
  background-color: #fde5c9;
  padding: 10px;
}
.create-center {
  display: inline-block;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 50px 0 50px;
}

.bold-text {
  font-weight: bold;
}

.site-owner {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
  background: lightgrey;
  border-radius: 10px;
  padding: 10px;
}

.form-check {
  text-align: left;
  padding-left: 20px;
}

.form-control {
  margin: 10px 0 10px 0;
  width: 800px;
}
</style>
